











































import { Component, Vue, Watch } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import ErrorAlert from '@/components/ErrorAlert.vue';

@Component({
  components: { ErrorAlert }
})
export default class CourtCounterStatisticDialog extends Vue {
  dialog: boolean = false;
  loading: boolean = false;
  error: any = null;

  year: string = format(Date.now(), 'yyyy');
  file: string | null = null;

  get download(): string {
    return `Gerichte-Bericht-${this.year}.pdf`;
  }

  get years(): string[] {
    const currentYear = new Date().getFullYear();
    let startYear = currentYear - 10;
    const years: string[] = [];

    while (startYear <= currentYear) {
      years.push((startYear++).toString());
    }

    return years.reverse();
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.year = format(Date.now(), 'yyyy');

      return;
    }

    setTimeout(() => {
      this.error = null;
      this.file = null;
      this.loading = false;
    }, 200);
  }

  async save() {
    this.error = null;

    this.loading = true;

    try {
      const response = await this.$http.get(`api/conservatorship/court-statistic-year-pdf`, {
        responseType: 'blob',
        params: { year: this.year }
      });

      this.file = URL.createObjectURL(response.data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
